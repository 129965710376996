import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/Seo'

interface IndexPageProps {
  data: any
  pageContext: {
    locale: string
    name: string
  }
}

const NotFoundPage: React.FC<IndexPageProps> = ({
  data: { prismicHeader, prismicFooter },
  pageContext,
}) => {
  return (
    <Layout
      pageContext={pageContext}
      headerData={prismicHeader.data}
      footerData={prismicFooter.data}
    >
      <SEO title="404: Not found" />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}
export default NotFoundPage

export const query = graphql`
  query IndexQuery2($locale: String!) {
    prismicHeader(lang: { eq: $locale }) {
      ...HeaderQuery
    }

    prismicFooter(lang: { eq: $locale }) {
      ...FooterQuery
    }
  }
`
